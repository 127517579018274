import { graphql } from 'gatsby';
import React from 'react';
import SingleTemplate from './single';
import { getIndexPageMeta } from '../utils/helpers';
import { PAGE_TYPE, PAGE_PREFIX } from '../utils/constants';
import BlogProxy from '../components/proxy/blog-blocks';

const LifeBlogIndexTemplate = props => {
  const { site, markdownRemark } = props.data;

  const seo = getIndexPageMeta({
    pathname: props.location.pathname,
    siteMetadata: site.siteMetadata,
    frontmatter: markdownRemark.frontmatter,
  });

  return (
    <SingleTemplate
      seo={seo}
      pageType={PAGE_TYPE.LifeBlog}
      pagePrefix={PAGE_PREFIX.LifeBlog}
      proxy={BlogProxy}
      {...props}
    />
  );
};

export default LifeBlogIndexTemplate;

export const lifeBlogIndexPageQuery = graphql`
  query LifeBlogIndexPageQuery(
    $filter: LifeBlog__PostFilterInput
    $pinned_slug: String
  ) {
    tags: allLifeBlogTag(filter: { visibility: { eq: "public" } }) {
      edges {
        node {
          name
          slug
          visibility
        }
      }
    }
    posts: allLifeBlogPost(
      filter: $filter
      sort: { fields: [published_at, featured], order: [DESC, DESC] }
    ) {
      edges {
        node {
          primary_author {
            name
            profile_image
          }
          primary_tag {
            name
          }
          id
          title
          slug
          feature_image
          published_at
          excerpt
        }
      }
    }
    featured: lifeBlogPost(slug: { eq: $pinned_slug }) {
      excerpt
      feature_image
      primary_tag {
        name
      }
      slug
      title
    }
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: "/life-at-bunny-index/" } }) {
      ...PageMarkdownFragment
    }
    ...ConfigFragment
  }
`;
